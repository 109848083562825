'use client'

import { useHydrateAtoms } from 'jotai/utils'
import { type ReactNode } from 'react'
import { userAtom } from 'app/(private)/session/atom'

export function HydrateSession({
  children,
  user,
}: {
  forceHydrate?: boolean
  children?: ReactNode
  user: { isSuperAdmin: boolean; permissions: { code: string }[] }
}) {
  useHydrateAtoms([[userAtom, user]])

  return children
}
