'use client'

import debounce from 'lodash/debounce'
import { useParams, useRouter } from 'next/navigation'
import { useCallback, useEffect } from 'react'

export function RevalidateRouterCache() {
  const router = useRouter()
  const params = useParams<{
    // both lessons and projects are using slug [id]
    // TODO, chagne them to [projectId] [lessonId]
    id: string
    phaseId: string
    sectionId: string
  }>()

  const { id, phaseId, sectionId } = params

  const getRefresher = useCallback(
    () =>
      debounce(
        () => {
          router.refresh()
        },
        50,
        { leading: false, trailing: true }
      ),
    [router]
  )

  const refresh = getRefresher()
  useEffect(refresh, [id])
  useEffect(refresh, [phaseId])
  useEffect(refresh, [sectionId])

  useEffect(() => {
    const callback = () => {
      refresh()
    }
    window.addEventListener('focus', callback)
    return () => {
      refresh.cancel()
      window.removeEventListener('focus', callback)
    }
  }, [])

  return null
}
